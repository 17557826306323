/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";
import Modal from "react-bootstrap/Modal"
import ModalHeader from "react-bootstrap/ModalHeader"
import ModalBody from "react-bootstrap/ModalBody"
import ModalFooter from "react-bootstrap/ModalFooter"
import ModalTitle from "react-bootstrap/ModalTitle"
import CreditCardForm from "./creditCardForm/creditCard.component"
import { getStripe } from "../utils/stripe";

export default class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: [],
      players: [],
      playerCnt: 0,
      error: '',
      total: 0,
      checkoutModal: false,
      parentForm: true,
      checkoutStep: 0,
      playerFormIndex: 0,
      playerDifferentLast: false,
      player:  {
        first: '', 
        last: '',
        birthday: '',
      },
      parent: {
        first: '',
        last: '',
        phone1: '',
        phone2: '',
        email: '',
        playersDifferent: false,
      },
      tempPlayerFirst: '',
      tempPlayers: {},
      disablePlayerInputForm: false,
      user: "",
      coachUsedFree:''
    };
  }
  
  async componentWillMount() {
    console.log("NODE ENV", process.env.NODE_ENV)
    if(localStorage.getItem("user")) this.setState({user: JSON.parse(localStorage.getItem("user"))}) 
    this.getData(true)
    if(JSON.parse(localStorage.getItem("user")))
      await Axios.post(`${this.props.api}/users`, {id: JSON.parse(localStorage.getItem("user")).id})
      .then(r => {
        r = r.data
        console.log("Coach used free", (r.type === "Coach" && r.coachUsedFree))
        if(r.type === "Coach" && r.coachUsedFree === true) this.setState({coachUsedFree: true})
      })
      .catch(e => console.log(e))
  }

  getData = async (updatePrice = false) => {
    let user = localStorage.getItem("user");
    if(!user) return;
    let id = JSON.parse(user).id;
    this.setState({id})
    if (user && user.length > 0) {
      await Axios.post(
        `${this.props.api}/users/cart`,
        { id }
      )
      .then((res) => {
        console.log("/users/cart/",res)
        this.setState({ cart: res.data.cart });
        let players = [];
        let total = 0
        res.data.cart.map(r => {
          total += (r.price*r.cnt)
          for(let i = 0; i < r.cnt; i++){
            this.setState({playerCnt: this.state.playerCnt + 1})
            players.push({ag: r.ag, index: i})
          }
          return true; // Supposedly this caused a bug(?), but I added it back becasue the IDE was freaking out
        })
        this.state.user.type === "Coach" && !this.state.coachUsedFree ? total-=130 : total+=0 
        this.setState({total})
        this.setState({players: players})
      })
      .catch((e) => console.log(e));
    }

    await this.updateTempPlayerValues()
    
    await Axios.post(`${this.props.api}/users`, {id:JSON.parse(user).id})
    .then(u => {
      // u = u.data
      let parentDetails = u.parentDetails
      console.log("u",u)
      console.log("Checkout step",u.checkoutStep)
      if(u.checkoutStep){
        let step = u.checkoutStep.toString().split(".")
        console.log("Step",step)
        this.setState({
          checkoutStep: parseInt(step[0]), 
          playerFormIndex:(parseInt(step[1])) <= 0 ? 0 : (parseInt(step[1]))
        }) // Check logic
      }
      this.setState({parent: {
        first: parentDetails.parentFirst,
        last: parentDetails.parentLast,
        email: parentDetails.parentEmail,
        phone1: parentDetails.phone1,
        phone2: parentDetails.phone2,
      }})
    })
    .catch(console.log)

    let p = JSON.parse(localStorage.getItem("user"))
    let user_name = p.name.split(" ")
    let parent = {...this.state.parent}
    parent.first = user_name[0]
    parent.last = user_name[1]
    parent.email = p.email
    this.setState({parent})

    if(this.state.player && this.state.player.first){
      let player = {...this.state.player}
      player.first = this.state[`${this.state.id}_${this.state.playerFormIndex}`].playerFirst || ''
      this.setState({player})
      console.log(this.state)
    }
  }

  updateTempPlayerValues = async () => {
    let user = localStorage.getItem("user");
    let id = JSON.parse(user).id;
    for(let i = 0; i < this.state.players.length; i++) {
      await Axios.post(`${this.props.api}/users/getTempPlayer`, {id, index:i})
      .then((res) => {this.setState({[`${id}_${i}`]: res.data})})
      .catch(console.log)
    }
  }

  update = async (ag, amount, price, uid) => { // Update user cart
    console.log("Update", ag, amount, price)
    let id = JSON.parse(localStorage.getItem("user")).id
    let cart = this.state.cart;
    await Axios.post(
      `${this.props.api}/users/cart/add`,
      { id, ag, cnt: amount, uid }
    )
    .then((res) => {
      if (res) console.log("res", res);
      if(res.status === 200) {
        let newAmount = cart[cart.findIndex((i) => i.ag === ag)].cnt + amount;

        this.setState({checkoutStep: 0}) // Needs more work to get it to properly adjust to removing players after entering their names

        this.setState({playerCnt: this.state.playerCnt+amount, total: 0})
        this.setState({
          cart: cart.map((c) => (c.ag === ag ? { ...c, cnt: newAmount } : c)),
        });
        let total = 0;
        this.state.cart.map(c =>  {
          return total+=c.price*c.cnt
        })
        total = total <= 0 ? 0 : total
        this.state.user.type === "Coach" && !this.state.coachUsedFree ? total-=130 : total+=0
        this.setState({total})
      } else {
        return this.setState({
          [`${ag}_full`]:"Full"
        })
      }
    })
    .catch((e) => console.log(e))
    .finally(this.setState({[`${ag}_full`]:false}))
}


  handleUpdate = (event) => {
    this.setState({[event.target.name]:event.target.value})
  }

  handleUpdatePlayer = (event) => {
    let player = {...this.state.player}
    player[event.target.name] = event.target.value
    this.setState({player})
  }

  handleUpdateParent = (event) => {
    let parent = {...this.state.parent}
    parent[event.target.name] = event.target.value
    this.setState({parent})
  }

  toggleCheckout = () => { // Toggle checkout modal
    this.setState({checkoutModal: !this.state.checkoutModal})
    if(this.state.checkoutStep === 0) {
      let player = {...this.state.player}
      player.first = this.state[`${this.state.id}_${this.state.playerFormIndex}`].playerFirst
      player.last = this.state[`${this.state.id}_${this.state.playerFormIndex}`].playerLast
      this.setState({player})
    }
  }

  updateCheckoutStep = async (newStep) => {
    console.log(newStep)
    newStep = newStep.split('.')
    console.log("int old", parseInt(newStep[0]))
    if(parseInt(newStep[0]) !== this.state.checkoutStep) newStep[0] = this.state.checkoutStep
    console.log("int new", parseInt(newStep[0]))
    newStep = newStep.toString().replace(",", ".")
    console.log("newStep", newStep)
    await Axios.post(`${this.props.api}/users/updateStep`, {id:JSON.parse(localStorage.getItem("user")).id, newStep})
    .then(res => {
      console.log(res)
    })
    .catch(e => console.log(e))
  }

  advance = async (direction) => {
    console.log("Advancing")
    this.state.checkoutStep < 1 || this.state.checkoutStep > 1 ? this.setState({disablePlayerInputForm: false}) : this.setState({disablePlayerInputForm: true})
    // if(this.state.checkoutStep === 0) this.updateParentInfo()
    if(this.state.checkoutStep === 1){

      let data = {
        index: this.state.playerFormIndex,
        id: this.state.id,
        ageGroup: this.state.players[this.state.playerFormIndex].ag,
        parentFirst: this.state.parent.first,
        parentLast: this.state.parent.last,
        parentEmail: this.state.parent.email,
        parentPhone1: this.state.parent.phone1,
        parentPhone2: this.state.parent.phone2,
        playerFirst: this.state.player.first || this.state[`${this.state.id}_${this.state.playerFormIndex}`].first,
        playerLast: this.state.player.last || this.state.parent.last,
      }

      await Axios.post(`${this.props.api}/users/updateTempPlayers`, data)
      .then((res) => {
        this.setState({disablePlayerInputForm: false})
        console.log("res", res.status, res.data)
        if(res.status === 202) {return this.state.error = res.data}
      })
      .catch(e => console.log(e))
      .finally(console.log('done'))
    }

      this.getData()  

      // If start or end, if -direction && pFI = 0, if +direction && pFI == len of players, advance form
      if(this.state.checkoutStep < 1 || this.state.checkoutStep > 1 || ( direction < 0 && this.state.playerFormIndex === 0 ) || (direction > 0 && this.state.playerFormIndex === this.state.players.length-1))
        return this.setState({checkoutStep: this.state.checkoutStep + direction}) 

      // If on player form, advance player form
      if(this.state.checkoutStep === 1 && ( this.state.playerFormIndex < this.state.players.length - 1 || this.state.playerFormIndex >= 0 )){
        this.setState({
          player: {
            first: this.state[`${this.state.id}_${this.state.playerFormIndex+direction}`].playerFirst || '',
            last: this.state.player.last
          }
        })
        return this.setState({playerFormIndex: this.state.playerFormIndex + direction})
      }
      
      console.log("Failed to advance")
  }
  
  parentForm = () => { // Parent info from
    return (
      <>
        <div className="form-group">
          <input placeholder="Parent first" value={this.state.parent.first}  className="form-control" id="parentFirst" name="parentFirst" required onChange={this.handleUpdateParent}/>
        </div>
        <div className="form-group">
          <input placeholder="Parent last" value={this.state.parent.last} className="form-control" id="parentLast" name="last" required onChange={this.handleUpdateParent} />
        </div>
        <div className="form-group">
          <input placeholder="Parent email" value={this.state.parent.email} type="email" className="form-control" id="email" name="email" required onChange={this.handleUpdateParent} />
        </div>
        <div className="form-group">
          <input placeholder="Main phone 6161231234" value={this.state.parent.phone1} type="tel" className="form-control" id="phone1" name="phone1" required onChange={this.handleUpdateParent} />
        </div>
        <div className="form-group">
          <input placeholder="Seconary phone (optional)" value={this.state.parent.phone2} type="tel" className="form-control" id="phone2" name="phone2" required onChange={this.handleUpdateParent} />
        </div>
        <div className="form-check">
          <input type="checkbox" className="form-check-input" id="differentPlayerLast" required onChange={this.handlePlayerLastSameAsParent}/>
          <label className="form-check-label" for="exampleCheck1" defaultChecked={true}>Player(s) have different last name</label>
        </div>
      </>
    )
  }

  playerForm = () => { // Player info form
    console.log("Player form")
    let currentPlayer = this.state.players[this.state.playerFormIndex];
    let tempPlayer = this.state[`${this.state.id}_${this.state.playerFormIndex}`]
    // console.log(this.state[`${this.state.id}_${this.state.playerFormIndex}`])
    return (
      <form action="register" method="">
        <p>{currentPlayer.ag} Player {currentPlayer.index+1} {this.state.error}</p>
        <div className="form-group">
          <input placeholder="Player name" value={this.state.player.first} type="text" className="form-control" name="first" required onChange={this.handleUpdatePlayer} disabled={this.state.disablePlayerInputForm} />
          {/* <input placeholder="Player Birtdhay" value={this.state.player.ageGroup} type="text" className="form-control" name="age-group" required onChange={this.handleUpdatePlayer} disabled={this.state.disablePlayerInputForm} /> */}
        </div>
        {(() => {
          if(this.state.parent.playersDifferent) {
            return (
              <div className="form-group">
                <input placeholder="Player last" defaultValue={tempPlayer ? tempPlayer.playerLast : ''} type="text" className="form-control" name="last" onChange={this.handleUpdatePlayerLast} required />
              </div>
            )
          }
        })()}
      </form>
    )
  }

  handlePaymentSuccess = () => {
    this.toggleCheckout()
    window.location.href = '/confirm'
  }

  payWithStripe = async () => {
    const checkoutSession = await Axios.post(`${this.props.api}/charge/stripe`, {id: this.state.user.id}).then(res => {
      return res.data
    });

    if (checkoutSession.statusCode === 500) {
      console.error(checkoutSession.message);
      return;
    }

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      sessionId: checkoutSession.id 
    });
    if(error) console.log(error)
  }

  creditCardForm = () => {
    return (
      <button className="btn btn-primary" onClick={() => this.payWithStripe()}>Confirm registration and pay</button>
    )
    // return (
    //   <CreditCardForm parentCallback={this.handlePaymentSuccess} amount={this.state.total*100} id={this.state.id} api={this.props.api} parent={JSON.parse(localStorage.getItem("user")).name}/>
    // )
  }

  afterPlayersForm = () => { // Placeholder
    return (
      <p>Next</p>
    )
  }

  coachRegister = async () => {
    // console.log("Coach registering")
    // this.setState({registering: true})
    // if(!this.state.registering) this.coachRegister()
    // if(this.state.registering)
      await Axios.post(`${this.props.api}/charge/coachFreeRegister`, {id: this.state.user.id, type: this.state.user.type}).then(res => {
        console.log("Saved coach players")
        this.setState({registering: false})
        return window.location.href = '/confirm'
      })
      .catch(e => {console.log(e); return(<p>Failed</p>)})
    return (<></>)
    // return (
      
    //   // <>
    //   //   <button className="btn btn-primary" onClick={() => this.toggleCheckout()}>Click here to finish</button>
    //   // </>
    // )
  }

  render = () => {
    let currentForm;
    let checkoutStage = '';
    var lastStep = false;
    var firstStep = true;
    // currentForm = this.playerForm()
    if(!this.state.cart) return(<></>)
    switch(this.state.checkoutStep) { 
      case 0: // Step 1 - Parent form
        firstStep = true;
        lastStep = false;
        currentForm = this.parentForm();
        checkoutStage = 'Enter parent info'
        break;
        case 1: // Step 2 - Player form
        firstStep = lastStep = false;
        currentForm = this.playerForm()
        checkoutStage = 'Enter player info'
        break;
      case 2: // Step 3 - Credit card form
        if(this.state.user.type === "Coach" && this.state.players.length <= 2 && !this.state.coachUsedFree){
          lastStep = true;
          firstStep = false;
          currentForm = <><button className="btn btn-primary" onClick={() => this.coachRegister()}>Click here to finalize registration</button></>
          // this.coachRegister() 
          // this.setState({registering:false})
        } else {
          lastStep = true;
          firstStep = false;
          currentForm = this.creditCardForm();
          checkoutStage = "Finish and pay";
        }
        break;
      default: // Temporary for now. Need to find better default.
        currentForm = "Form not complete";
        break;
    }
    return (
      <>
        <div>
          <Modal show={this.state.checkoutModal}>
            <ModalHeader>
              <ModalTitle>Checkout - {checkoutStage}</ModalTitle>
              <button type="button" className="close" data-dismiss="modal" onClick={() => this.toggleCheckout()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody>
              {currentForm}
            </ModalBody>
            <ModalFooter>

              {!firstStep && ( // Enable on any step that isn't the first
                <button type="submit" className="btn btn-primary proceedBtn" onClick={() => { this.advance(-1) }} >Previous</button>
              )}

              {!lastStep && ( // Enable on any step that isn't the last
                <button type="submit" className="btn btn-primary proceedBtn" onClick={() => { this.advance(1) }} >{this.state.user.type === "Coach" && !this.state.coachUsedFree && this.state.playerFormIndex === this.state.players.length ? "Finish" : "Next"}</button>
              )}
              
              <button className="btn btn-primary" data-dismiss="modal" onClick={() => this.toggleCheckout()}>Close</button>
            </ModalFooter>

          </Modal>
        </div>
        <div className="row mt-4">
          <div className="col">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Age Group</th>
                  <th scope="col">Count</th>
                  <th></th>
                </tr>
              </thead>
              {this.state.cart && this.state.cart.length < 0 && (
                <></>
              )}
              {(() => {
                if(this.state.cart && this.state.cart.length < 0) {
                  return(<img src='/loading.gif' className="loadingGif" alt=""/>)
                }
              })()}
              <tbody>
              {this.state.cart.sort((a,b) => b.uid - a.uid).map((item, key) => {
                  if(item.cnt > 0){
                    return (
                      <tr key={key}>
                        <td>{item.ag} {this.state[`${item.ag}_full`]}</td>
                        <td>{item.cnt}</td>
                        <td>
                          <a
                            className="btn btn-success"
                            role="button"
                            href="# "
                            onClick={() => this.update(item.ag, 1, item.price, item.uid)}
                            disabled={this.state.disablePlayerInputForm || this.state[`${item.ag}_full`]}
                          >
                            <FaPlus />
                          </a>
                          &nbsp;
                          <a
                            className="btn btn-warning"
                            role="button"
                            href="# "
                            onClick={() => this.update(item.ag, -1, -item.price, item.uid)}
                            disabled={this.state.disablePlayerInputForm}
                          >
                            <FaMinus />
                          </a>
                          &nbsp;
                          <a
                            className="btn btn-danger"
                            role="button"
                            href="# "
                            onClick={() => this.update(item.ag, -item.cnt, (-item.price*item.cnt), item.uid)}
                            disabled={this.state.disablePlayerInputForm}
                          >
                            <FaTrash />
                          </a>
                          {/* <span> {this.state.error}</span> */}
                        </td>
                      </tr>
                    );
                  } else {
                    return <></>
                  }
              })}
              <tr><br /><a href="register" className="btn btn-success btn-large btn-block text-white" style={{fontSize: "20px", width: "100%"}}>Click
                  here to register {this.state.cart.length > 0 ? 'more' :'' } players</a></tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-auto"></div>
          <div className="col-lg-4">
            <div className="card">
                <div className="card-body summary">
                  <h4>Registration summary</h4> 
                  {this.state.cart.sort((a,b) => b.uid - a.uid).map((c, key) => {
                    if(c.cnt > 0) {
                      console.log(c)
                      return (
                        <p key={key}>${c.price*c.cnt} {c.ag} ({c.cnt} players)</p>
                      )
                    } else {
                      return <></>
                    }
                  })}
                  {(() => {
                    if(this.state.user.type === "Coach" && !this.state.coachUsedFree) return(<p>-$120 | Coach Discount</p>)
                  })()}
                  <hr />
                  <p>Total: ${(() => {
                    let total = 0
                    this.state.cart.map(c =>  {
                      return total+=c.price*c.cnt
                    })
                    if(this.state.user.type === "Coach" && !this.state.coachUsedFree) total = total-130
                    total = total <= 0 ? 0 : total
                    return total <= 0 ? 0 : total
                  })()}</p>
                  <button className="btn btn-primary" onClick={() => {this.state.cart.length > 0 ? this.toggleCheckout() : console.log()}}>Check out</button>
                </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
