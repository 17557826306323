/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";

export default class UserControl extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            port: this.props.port,
            users: [],
            users2: [],
            ag: [],
            setCoach: false,
        };
    }

    async componentDidMount() {
        await Axios.post(
            `${this.props.api}/users/all`,
            {
                uid: JSON.parse(localStorage.getItem("user")).id,
            }
        )
        .then(async(res) => {
            console.log(res);
            let users = [];
            for (let user in res.data){ 
                users.push(res.data[user]);
                this.setState({[`${res.data[user]._id}`]:`${res.data[user].type}`})
                // await Axios.get(`${this.props.api}/`)
                await Axios.post(`${this.props.api}/users/getPlayers`, {id:res.data[user]._id})
                .then((p) => {
                    this.setState({[`${res.data[user]._id}_players`]:p.data})
                })
                .catch(e => console.log(e))
            }
            this.setState({ users , users2:users });
        })
        .catch((e) => console.log(e));

        await Axios.get(`${this.props.api}/ag`)
        .then(ag => this.setState({ag:ag.data,}))
        .catch(e => console.log(e))
    }

    setCurrentUser(user) {
        this.setState({ currentUser: user });
    }

    handleChange = async (event, id) => {
        console.log(event.target.value)
        if(event.target.value === "Coach") {
            this.setState({setCoach: true})
        } else {
            this.tryRemoveCoach(this.state.currentUser)
        }
        this.setState({[`${id}`]:event.target.value})
        await Axios.post(`${this.props.api}/users/updateUserType`, {id, type: event.target.value})
        .then(res => {
            console.log("Account type updated")
        })
        .catch(e => console.log(e)) 
    }

    setCoachGroup = (event) => {
        this.setState({coachGroup: event.target.value})
    }

    tryRemoveCoach = async (id) => {
        console.log("id",id)
        await Axios.post(`${this.props.api}/users/tryRemoveCoach`, {id})
        .then(c => {
            console.log(c)
            if(c.status === 200) {
                console.log("user removed from coaches")
            }
        })
        .catch(e => console.log(e))
        
    }

    updateCoach = async (id, group) => {
        await Axios.post(`${this.props.api}/users/assignCoach`, {id, group})
        .then(c => {
            console.log("update coach")
            // if(c.status === 200){
                console.log(c)
                this.setState({setCoach: false})
            // }
        })
        .catch(e => console.log(e))
    }

    toggleUserControl = (id) => {
         // Open a modal that allows to access the user account details 
         // I.E - 
         // Login to that acount
         // Reset cart
         // Reset temp players
        this.setState({[`${id}_modal`]:!this.state[`${id}_modal`]})
    }

    togglePlayerModal = (id, uid) => {
        this.setState({[`${id}_modal`]:!this.state[`${id}_modal`]})
    }

    handleSearch = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    search = (e) => {
        if(e.target.value === "") this.setState({users: this.state.users2})
        this.setState({
            users: this.state.users2.filter((s) => {
                return s.name.toLowerCase().includes(e.target.value.toLowerCase()) || s.email.toLowerCase().includes(e.target.value.toLowerCase())
            })|| this.state.users2
        })
    }

    adminManualLogin = async (user) => {
        await Axios.post(`${this.props.api}/login/adminManualLogin`, {
            admin: JSON.parse(localStorage.getItem("user")).id,
            user
        })
        .then(r => {
            localStorage.setItem("user", JSON.stringify(r.data))
            window.location.href = '/'
        })
    }

    render() {
        return (
            <>
                <div className="form-group">
                    <input disabled={this.state.users2 < 1} className="form-control" placeholder="Search by name or email" value={this.state.search} name="search" onChange={(e) => {this.handleSearch(e); this.search(e)}}></input>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((user, index) => {
                            return (
                                <>
                                    <Modal show={this.state[`${user._id}_modal`]}>
                                        <ModalHeader>
                                            {user.name}
                                            <button type="button" className="close" data-dismiss="modal" onClick={() => this.toggleUserControl(user._id)} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </ModalHeader>
                                        <ModalBody>
                                            {/* {this.state[`${user._id}_players`].map((player, index) => {
                                                return (
                                                    <>
                                                        <p key={index}>
                                                            {player.ageGroup} {player.playerFirst.split(" ").length > 0 ? player.playerFirst.split(" ")[0] : player.playerFirst } {player.playerLast}
                                                        </p>
                                                    </>
                                                )
                
                                            })}
                                            {this.state[`${user._id}_players`].length < 1 && (
                                                <p>No players registered</p>
                                            )} */}
                                        </ModalBody>
                                    </Modal>
                                    <tr key={index}>
                                        {/* eslint-disable-next-line */}
                                        <th><a href='' onClick={() => this.toggleUserControl(user._id)}>{user.name}</a></th> {/* Toggle User Control Panel - View user's registered players*/}
                                        {/* eslint-disable-next-line */}
                                        <td><a href='' onClick={() => this.adminManualLogin(user.email)}>{user.email}</a></td> {/* Manually login to a users account from admin page */}
                                        <td>
                                            <select
                                                name={user._id}
                                                onFocus={() => this.setCurrentUser(user._id)}
                                                onChange={e => (this.handleChange(e, user._id))}
                                                value={this.props.user.email === user.email ? this.props.user.type : this.state[`${user._id}`]}
                                                disabled={this.props.user.email === user.email}
                                            >
                                                <option value="Parent">Parent</option>
                                                <option value="Coach">Coach</option>
                                                
                                                <option value="Admin">Admin</option>
                                                {/* These two only work if account is user account is super admin */}
                                                {/* {this.props.user.type === "Super Admin" && (
                                                    <>
                                                        <option value="Super Admin">Super Admin</option>
                                                    </>
                                                )} */}
                                            </select>
                                        </td>
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
                {this.state.users.length < 1 && (
                    <img src="/loading.gif" className="loadingGif" alt="" />
                )}
                {this.state.setCoach && (
                    <Modal show={true}>
                        <ModalHeader>Assign coach to age group</ModalHeader>
                        <ModalBody>
                            <select onFocus={this.setCoachGroup} onChange={this.setCoachGroup} value={this.state.coachGroup} >
                                <option>Set group</option>
                                {this.state.ag && this.state.ag.map((a, i) => {
                                    return (<option key={i} value={a.ageGroup}>{a.ageGroup}</option>)
                                })}
                                <option value="2Free">2 Free</option>
                            </select>
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-primary" onClick={() => {this.updateCoach(this.state.currentUser, this.state.coachGroup)}}>Assign</button>
                        </ModalFooter>
                    </Modal>
                )}
            </>
        );
    }
}
