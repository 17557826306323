/* eslint-disable */
import axios from "axios";
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal"
import ModalHeader from "react-bootstrap/ModalHeader"
import ModalBody from "react-bootstrap/ModalBody"
import ModalFooter from "react-bootstrap/ModalFooter"

export default class Cards extends Component {
    constructor(props){
      super(props)
      this.port = this.props.port;
      this.toggleModal = this.toggleModal.bind(this)
      this.state = {
        displayLoading: true,
        loadingMessage: 'Loading....',
        port: this.props.port,
        cards: [],
        displays: [],
        data: '',
        currentAg: '',
        currentAgCnt: 0,
        currentUid: 0,
        error: '',
        formSubmit: false
      }
      this.loggedIn = localStorage.getItem("user") && localStorage.getItem("user").length > 0 ? true : false;
    }   

    handleData(data){ 
      this.setState({data:JSON.parse(data)})
    }

    handleAgRegUpdate = (event) => { // Handle when current ag is changed
      this.setState({currentAgCnt:event.target.value})
    }

    async componentWillMount(){
      // console.log(window.location)
      await axios.get(`${this.props.api}/ag/`) // Get database
      .then(res => {
        this.setState({
          cards: res.data
        })
        this.genModalStates();
        this.setState({displayLoading: false})
      })
      .catch(e => {
        // console.log(e);
        this.setState({loadingMessage: "Failed to load"})
      })
    }

    renderAgFull = (c) => { // Render full
      return (
        <div>
          <p>
            <button type="button" className="btn btn-primary disabled" data-toggle="modal">Age Group Full</button>
          </p>
          <p>All available spots have been filled</p>
        </div>
      )
    }

    renderAgOpen = (c) => { // Render open
      return (
        <>
          <div>
            <p><button className="btn btn-primary" onClick={() => this.toggleModal(c._id, c.ageGroup, c.uid)}>Register</button></p>
            {c.reason ? c.reason : ''}
          </div>
          <p>( Available: {c.max-c.filled}/{c.max} )</p>
        </>
      )
    }

    renderAgClosed = (c) => { // Render closed
      if(c.reason.length > 0){
        c.reason = <p>Due to {c.reason}, this age group is closed.</p>
      }
      return (
        <div>
          <p><button type="button" className="btn btn-primary disabled">Age Group Closed</button></p>
          {c.reason || 'Closed until further notice'}
        </div>
      )
    }

    genModalStates = () => { // Generate states for each modal. Disabled by default.
      let arr = [];
      this.state.cards.map(c => {
        return arr.push({id: c._id, display: false})
      })
      this.setState({displays: arr})
    }

    toggleModal = (id, ag, uid) => { // Toggle modal based off of modal id
      this.setState({error: ""})
      // console.log(id, ag, uid)
      let dis = this.state.displays
      let display = !dis[dis.findIndex(i => i.id === id)].display;
        this.setState({
        displays: this.state.displays.map(d => (d.id === id ? Object.assign({}, d, {display}) : d)),
        currentAg: ag,
        currentUid: uid
      })
    }

    add = async (id, price) => { // Add to cart
      // console.log(id, price)
      this.setState({formSubmit: true})
      await axios.post(`${this.props.api}/users/cart/add`, {
        id,
        ag: this.state.currentAg,
        cnt: this.state.currentAgCnt < 1 ? 1 : this.state.currentAgCnt,
        uid: this.state.currentUid, // UID of ag 
        price
      })
      .then((res) => {
        // console.log(res)
        if(res.status === 200){
          window.location.href = "/cart"
        } else {
          // this.setState({error:"Sorry, but you've entered too many players"})
          this.setState({error: res.data})
        }
      })
      .catch((e) => console.error(e))
      .finally(this.setState({formSubmit: false}))
    }

    render() {
      return (
        <div className="row mt-4">
          <br />
          {this.state.cards.length < 1 && (
            <img src='/loading.gif' className="loadingGif" alt=""/>
          )}
          { this.state.cards.sort((a,b) => a.uid - b.uid).map((c, i) => {
            try{ //Dialetheism code (true and false at the same time). It works but it also errors out since it cant find the data which it does find
              var displayState = this.state.displays.filter(d => d.id === c._id)[0]
              displayState = displayState.display;
            } catch(e) {
              // console.log(e) /* Commented because I don't like them uneccesary error*/
            }
            return (
              <>
              <div className="col-lg-4 col-md-6 mb-4" key={i}>
                <div className="card h-20 text-center">
                  <div className="card-body">
                    <h4 className="card-title">Ages {c.ageGroup.replace('-', ' & ')}</h4>
                  </div>
                  <div className="card-footer">
                  {(() => {
                      switch(c.status){
                        case "closed":
                          return this.renderAgClosed(c);
                        case "full":
                          return this.renderAgFull(c);
                        case "open":
                          return this.renderAgOpen(c);
                        default: 
                          break;
                      }
                    })()}
                    {/* {(() => {
                      if(c.disAva){
                        return <p>( Available: {c.max-c.filled}/{c.max} )</p>
                      }
                    })()} */}
                  </div>
                </div>
              </div>
              <div>
                <Modal show={displayState}>
                  <ModalHeader>
                    {(() => {
                      if(this.loggedIn){
                        return (
                          <h5>{c.ageGroup.replace("-", " & ")}</h5>
                        )
                      } else {
                        return (
                          <h5>You must be logged in to register players</h5>
                        )
                      }
                    })()}
                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.toggleModal(c._id, c.ageGroup, c.uid)} aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </ModalHeader>
                  {(() => {
                    if(this.loggedIn){
                      return (
                        <>
                          <ModalBody>
                            <p>(${c.price}) How many players would you like to register?</p>
                            {(() => {
                              if(this.state.error){
                                return (
                                <div className="alert alert-danger" role="alert">
                                  {this.state.error}
                                </div>
                              )
                              }
                            })()}
                            <div className="row">
                              <div className="col-sm-4">
                                <label for="quantity" className="col-form-label">Player Quantity:</label>
                              </div>
                              <div className="col-sm-8">
                                <select className="form-control" id="quantity" name={`${c.ageGroup}_quantity`} onChange={this.handleAgRegUpdate} disabled={this.state.formSubmit} required>
                                  {Array((c.max-c.filled>7 ?  7 : c.max-c.filled)+1).fill(1).map((el, i) => {
                                    if(i > 0) return(<option value={i}>{i}</option>)
                                  })}
                                </select>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <button type="submit" className="btn btn-primary proceedBtn" disabled={(parseInt(this.state.currentAgCnt)+c.filled > c.max ? true : false) || this.state.formSubmit} onClick={() => this.add(JSON.parse(localStorage.getItem("user")).id, c.price)}>Add</button>
                            <button className="btn btn-primary" data-dismiss="modal" onClick={() => this.toggleModal(c._id, c.ageGroup, c.uid)}>Close</button>
                          </ModalFooter>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <ModalBody>
                          {/* eslint-disable-next-line */}
                            <a herf="/login"><a href="login">Click here to login or signup for an account</a></a>
                          </ModalBody>
                        </>
                      )
                    }
                  })()}
                  
                </Modal>
              </div>
              </>
            )
          }) }
        </div>
      );
    }
}
