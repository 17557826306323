/* eslint-disable */
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from "axios";

class Confirm extends Component { 
  constructor(props){
    super(props)
    this.state = {
      sessionId: this.props.match.params.session_id,
      api: this.props.api,
      port: this.props.port,
      user: JSON.parse(localStorage.getItem("user"))
    }
  }

  async componentWillMount() {
    console.log(this.state.sessionId);
    const {id, accountType} = this.state.user;
    await axios.post(`${this.state.api}/charge/save-players`, {id, accountType}).then(() => {
      console.log("Players registered");
    }).catch(e => console.log(e))
  };

  render() {
    return (
      <div>
          <br />
          <h3>Players have been registered. See you September 21!</h3>
      </div>
    );
  }
}

Confirm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      session_id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Confirm);