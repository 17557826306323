import React, { Component } from "react";

export default class Coach extends Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <div>
          <p>Coach</p>
      </div>
    );
  }
}
