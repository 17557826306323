/* eslint-disable */
import React, { Component } from "react";

export default class Terms extends Component {
  constructor(props){
    super(props);
    this.state = {
      port: this.props.port
    }
  }
  render() {
    return (
      <div>
        <br></br>
        <div className="card">
            <div className="card-body">
            <h3>Terms and conditions</h3>
            You agree to hold Bre Soderberg, and/or her agents, insurers, representatives, successors and assigns (or any alias thereof), harmless from any and all liability , claims and demands of whatever kind of nature, either in law or in equity, in the event of any accident, injury, or loss that may occur or arise while participating in the home school soccer program.
            </div>
        </div>
      </div>
    );
  }
}
