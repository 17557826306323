/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";

export default class LoginRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRegister = async () => {
    console.log("Handle register")
    const { email, password, name } = this.state;
    // if (!this.state.email_error) return;
    await Axios.post(
      `${this.props.api}/login/add`,
      { email, password, name }
    )
    .then(async (res) => {
      if (res.status === 200) {
        const {email, password} = this.state
        await Axios.post(`${this.props.api}/login`, {
          email,
          password
        })
        .then((res) => {
          if(res.status !== 200){
            this.setState({error: res.data.message})
          } else {
            localStorage.setItem("user", JSON.stringify(res.data))
            window.location.href = '/register';
          }
        })
        .catch((err) => {
          console.log(err)
        })
      }
    })
    .catch((e) => console.log(e));
  };

  validateEmail = async () => {
    // return; // Temporary - Need to figure out some bugs
    const { email } = this.state;
    if (this.state.email && this.state.email.includes("@")) {
      await Axios.post(
        `${this.props.api}/login/validateEmail`,
        { email }
      )
        .then((res) => {
          console.log(res);
          if (res.status === 200)
            return this.setState({ email_error: "Email found" });
          if (res.status === 202) return this.setState({ email_error: "" });
        })
        .catch((e) => console.log(e));
    } else {
      this.setState({ email_error: "" });
    }
  };

  handleChange = (event) => {
    if(event.target.name === "email"){
      this.setState({email_error:''})
      // check if email contains regex @[a-zA-Z].[a-zA-Z] (not exact regex, but you get the pattern)
    }
    this.setState({
      [`${event.target.name}`]: event.target.value,
    });
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div className="card card-signin my-5">
              <div className="card-body">
                <h5 className="card-title text-center"> Register Account </h5>
                <form
                  className="form-signin"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.handleRegister();
                  }}
                >
                  <div className="form-label-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="First + Last name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      required
                      autofocus
                    />
                    <br />
                  </div>
                  {this.state.name && this.state.name.split(/[a-zA-Z]\s[a-zA-Z]/).length < 2 && (
                    <p>You must enter a last name</p>
                  )}  
                  <div className="form-label-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email address"
                      value={this.state.email}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      onBlur={(e) => {
                        this.validateEmail(e)
                      }}
                      required
                    />
                    <br />
                  </div>
                  {this.state.email_error && <p> Email already used </p>}
                  <div className="form-label-group">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      required
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <br />
                  </div>
                  <div className="form-label-group">
                    <input
                      type="password"
                      name="password2"
                      className="form-control"
                      placeholder="Confirm password"
                      required
                      value={this.state.password2}
                      onChange={this.handleChange}
                    />
                    <br />
                  </div>
                  {this.state.password &&
                    this.state.password2 &&
                    this.state.password !== this.state.password2 && (
                      <p> Passwords don't match!</p>
                    )}
                  {this.state.error && <p> {this.state.error} </p>}
                  <button className="btn btn-lg btn-primary btn-block ">
                    
                    Register
                  </button>
                  <span>By registering you agree to the <a href="/terms">terms and conditions</a></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
