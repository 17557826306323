/* eslint-disable */
import React from "react";
import Card from "react-credit-cards";

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./utils";

import Axios from "axios";

export default class CreditCardForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.id,
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      issuer: "",
      focused: "",
      formData: null,
      paymentSuccess: false,
      formSubmit: false
    }
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
  };

  handlePay = async () => {
    this.setState({
      formSubmit: true,
      number_error: '',
      exp_error: '',
      cvc_error: '',
      name_error: ''
    })
    await Axios.post(`${this.props.api}/charge`, {
      id: this.state.id,
      card: {
        number: this.state.number,
        exp: this.state.expiry,
        cvc: this.state.cvc,
        amount: this.props.amount
      },
      parentName: this.props.parent
    })
    .then((res) => {
      if(res.status === 200) {
        console.log("Payment success")
        this.form.reset()
        this.setState({paymentSuccess: true})
        return this.props.parentCallback()
      } else if(res.status === 202){
        this.setState({formSubmit: false})
        switch(res.data.code) {
          case 'invalid_expiry_year':
            this.setState({exp_error: res.data.raw.message})
            break;
          case 'incorrect_number':
            this.setState({number_error: res.data.raw.message})
            break;
          case 'incorrect_cvc':
            this.setState({cvc_error: res.data.raw.message})
            break;
          default: 
            this.setState({card_error: res.data.raw.message})
        }
      } else {
        return console.log(res.status, "Payment failed", res.data)
      }
      console.log(res)
    })
    .catch(console.log)
  }

  savePlayers = async () => {
    await Axios.post(`${this.props.api}/users/savePlayers`)
  }

  render() {
    const { name, number, expiry, cvc, focused, issuer } = this.state;

    return (
      <div key="Payment">
        <div className="App-payment">
          {/* {this.state.formSubmit && (
            <img src='/loading.gif' className="loadingGif checkoutLoading" alt=""/>
          )} */}
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          <br />
          {this.state.card_error}
          <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="tel"
                name="number"
                className="form-control"
                placeholder="Card Number"
                pattern="[\d| ]{16,22}"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                disabled={this.state.formSubmit}
              />
              {this.state.number_error}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Name"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                disabled={this.state.formSubmit}
              />
              {this.state.name_error}
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="Valid Thru"
                  pattern="\d\d/\d\d"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  disabled={this.state.formSubmit}
                />
                {this.state.exp_error}
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  name="cvc"
                  className="form-control"
                  placeholder="CVC"
                  pattern="\d{3,4}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  disabled={this.state.formSubmit}
                />
                {this.state.cvc_error}
              </div>
            </div>
            <input type="hidden" name="issuer" value={issuer} />
            <div className="form-actions">
              <br />
              <button className="btn btn-primary btn-block" type="button" disabled={this.state.formSubmit} onClick={() => this.handlePay()}>
                Pay ${this.props.amount/100}
                &nbsp;{this.state.formSubmit && (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                )}
              </button>
            </div>
          </form>
          {this.state.paymentSuccess && (
            <p>Payment success!</p>
          )}
        </div>
      </div>
    );
  }
}
