import React, { Component } from "react";

export default class Parent extends Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <div>
          <p>Parent</p>
      </div>
    );
  }
}
