/* eslint-disable */

import React, { Component } from "react";

import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

export default class RegisterControl extends Component {

  constructor(props){
    super(props);
    this.state = {
      port: this.props.port,
      groups: [],
      loaded: false,
      currentValue: '',
      currentAg: ''
    }
    this.groups = []
  }

  async componentWillMount(){
    console.log(`${this.props.api}`)
    await Axios.get(`${this.props.api}/ag`)
    .then(async res => {
      console.log("res", res.data)
      let groups = []
      for(let group in res.data) {
        console.log({[`${res.data[group]._id}`]:`${res.data[group].status}`})
        this.setState({[`${res.data[group]._id}`]:`${res.data[group].status}`})
        groups.push(res.data[group])
        console.log("Pushed")
        try{
          await Axios.post(`${this.props.api}/ag/players`, {
            adminId: JSON.parse(localStorage.getItem("user")).id,
            ageGroup: res.data[group].ageGroup
          })
          .then(r => {
            console.log("group data",r)
            this.setState({[`${res.data[group]._id}_players`]:r.data})
          })
          .catch(e => {
            console.log(e)
          })
        } catch(e) {
          console.log("Failed to get player data")
        }
      }
      this.setState({groups})
    })
    .catch(console.log)
    .finally()

  }

  handleChange = (event, id) => {
    if(event.target.value !== "nothing") {
      this.setState({currentValue: event.target.value, [`${id}`]:event.target.value})
      console.log("Change:", event.target.value)
      this.updateStatus(this.state.currentAg, event.target.value)
    } 
  }

  setCurrentAg (ag) {
    this.setState({currentAg:ag})
  }

  async updateStatus(id, status) {
    console.log("Updating")
    await Axios.post(`${this.props.api}/ag/update`, {id, status})
    .then(res => {
      console.log(res.status)
      console.log(res.data)
    })
    .catch(e => console.log(e))
  }

  toggleGroupControl = (id) => {
    this.setState({[`${id}_modal`]:!this.state[`${id}_modal`]})
  }

  // getParentPhone = async (id) => {
  //   await Axios.post(`${this.props.api}/users/getData`, {
  //     parentId: id,
  //     adminId: JSON.parse(localStorage.getItem("user")).id,
  //     data: ['parentPhone1', 'parentPhone2']
  //   }).then(res => {
  //     console.log("Parent phone res", res)
  //   })
  //   .catch(e => console.log(e))
  //   .finally(() => {return 123})
  // }

  render() {
    // if(this.props.user && (this.props.user.type !== "Admin" || this.props.user.type !== "Super Admin")){
    //   window.location.href = '/'
    // }
    return (
      <>
        <table className="table">
          <thead>
              <tr>
              <th scope="col">Age Group</th>
              <th scope="col">Status</th>
              <th scope="col">Filled</th>
              </tr>
          </thead>
          <tbody>
            {
              this.state.groups.map((group, index) => {
                return ( // CHANGE TO TABLE
                  <>
                    <Modal show={this.state[`${group._id}_modal`]}>
                      <ModalHeader>
                          {group.ageGroup}
                          <button type="button" className="close" data-dismiss="modal" onClick={() => this.toggleGroupControl(group._id)} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                      </ModalHeader>
                      <ModalBody>
                          {/* {this.state[`${group._id}_players`].map((player, index) => {
                            <p key={index}>
                              {player.firstName} {player.lastName} - {player.parentFirst} {player.parentLast}
                              {player.playerFirst}
                            </p>
                          })} */}
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Player</th>
                                <th>Parent</th>
                                <th>Phone</th>
                              </tr>
                            </thead>
                            <tbody>    
                              {this.state[`${group._id}_players`].map((player, i ) => {
                                return (
                                  <tr key={i}>
                                    <td>{player.playerFirst.split(" ").length > 0 ? player.playerFirst.split(" ")[0] : player.playerFirst } {player.playerLast}</td>
                                    <td>{player.parentFirst} {player.parentLast}</td>
                                    <td>{player.parentPhone1} {player.parentPhone2}</td>
                                  </tr>
                                  )
                              })}
                            </tbody>
                          </table>
                          {this.state[`${group._id}_players`].length < 1 && (
                            <p>No players registered</p>
                          )}
                      </ModalBody>
                    </Modal>
                    <tr key={index}>
                      {/* eslint-disable-next-line */}
                      <th><a href="#" onClick={() => this.toggleGroupControl(group._id)}>{group.ageGroup}</a></th>
                      <td>
                        <select onFocus={() => this.setCurrentAg(group._id)} onChange={e => {this.handleChange(e, group._id)}} value={this.state[`${group._id}`]}>
                          {group.filled < group.max && (
                            <option value="open">Open</option>
                          )}
                          <option value="closed">Closed</option>
                          <option value="full">Full</option>
                        </select>
                      </td>
                      <td>{group.filled}/{group.max}</td>
                    </tr>
                  </>
                )
              })
            }
          </tbody>
        </table>
        {this.state.groups.length < 1 && (
          <img src='/loading.gif' className="loadingGif" alt=""/>
        )}
      </>
    );
  }
}
