/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";  

export default class Login extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      error: '',
      email:'',
      password: '',
    }
    this.handleLogin.bind(this)
  }

  handleChange = (event) => {
    this.setState({[event.target.name]:event.target.value})
  }

  async handleLogin(){
    const {email, password } = this.state;
    await Axios.post(`${this.props.api}/login`, {
      email,
      password
    })
    .then((res) => {
      if(res.status !== 200){
        this.setState({error: res.data.message})
      } else {
        localStorage.setItem("user", JSON.stringify(res.data))
        window.location.href = '/register';
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }
  
  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card card-signin my-5">
                <div className="card-body">
                  <h5 className="card-title text-center">Sign In</h5>
                  <form className="form-signin" onSubmit={(e) => {e.preventDefault(); this.handleLogin()}}>
                    <div className="form-label-group">
                      <input type="email" name="email" id="email" className="form-control" placeholder="Email address" value={this.state.email} onChange={this.handleChange} required autofocus />
                      <label for="email" />
                      <br />
                    </div>
                    <div className="form-label-group">
                      <input type="password" name="password" id="password" className="form-control" placeholder="Password" required value={this.state.password} onChange={this.handleChange}/>
                      <br />
                    </div>
                    {this.state.error && (
                      <p>{this.state.error}</p>
                    )}
                    <p><a href="/login/register">Click here to sign up for an account</a></p>
                    <button className="btn btn-lg btn-primary btn-block ">Login</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
