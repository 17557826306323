import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom"
import 'bootstrap/dist/js/bootstrap'
import 'jquery/dist/jquery'
import './assets/css/nav.css'
import './assets/css/register_loading.css'
import './assets/css/style.css'
import './assets/css/cart.css'
import 'react-credit-cards/es/styles-compiled.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import Navbar from './components/navbar.component';
import Home from './components/home.component';
import Register from './components/register.component'
import Login from './components/login.component';
import Admin from './components/admin/admin.component';
import Cart from './components/cart.component';
import Confirm from './components/confirm.component'
import LoginRegister from './components/login.register.component';
import Coach from './components/coach/coach.component';
import Parent from './components/parent/parent.component';
import Terms from './components/terms.component';

// import AdminPanelComponent from './components/adminPanel/admin.component'

// require('dotenv').config('../../.env')

export default function App () {
  const port = process.env.REACT_APP_PORT || 5000
  // console.log("port", port)
  // console.log(process.env)
  // let user = JSON.parse(localStorage.getItem("user")) || {}
  let user
  let loggedIn = user ? true : false

  let api = `${process.env.NODE_ENV === "production" ? 'https' : 'http'}://${window.location.hostname}${process.env.NODE_ENV === "production" ? '' : `:${port}`}`
  return (
    <Router>
      <Navbar port={port} api={api}/>
      {/* {location.pathname} */}
      <div className="container">
          <Route path="/" exact component={Home} />
          {/* <Route path="/adminPanel" render={(props) => <AdminPanelComponent {...props}/>}/> */}
          <Route exact path="/register" render={(props) => <Register {...props} port={port} api={api}/>}/> 
          <Route exact path="/login" render={(props) => <Login {...props} port={port} api={api}/>}/>
          <Route exact path="/login/register" render={(props) => <LoginRegister {...props} port={port} api={api}/>}/>
          <Route exact path="/admin" render={(props) => <Admin {...props} port={port} user={user} loggedIn={loggedIn} api={api}/>} />
          <Route exact path="/coach" render={(props) => <Coach {...props} port={port} user={user} api={api}/> } />
          <Route exact path="/parent" render={(props) => <Parent {...props} port={port} user={user} api={api}/>} />
          <Route exact path="/cart" render={(props) => <Cart {...props} port={port} api={api} />} />
          <Route exact path="/confirm/:session_id" component={(props) => <Confirm api={api} port={port}/>} />
          <Route exact path="/logout" render={() => {
            localStorage.setItem("user", ''); // Reset user local storage
            return window.location.href = '/'}} 
          />
          <Route exact path="/terms" component={Terms} />
      </div>
    </Router>
  );
}
