/* eslint-disable */
import React, { Component } from "react";
import Cards from "./cards.component";

export default class Register extends Component {
  constructor(props){
    super(props);
    this.state = {
      port: this.props.port
    }
  }
  render() {
    return (
      <div>
          <Cards port={this.state.port} api={this.props.api}/>
      </div>
    );
  }
}
