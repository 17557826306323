import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise;

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51JIyOoEsmnzqaUlKsgIiLwjXxivKtty44ep6Vy4hhGzOav9BozTcRT96HfryJo2xBHjVWJu7hKwdspM23pryaqA200XvGaz5hK"
    );
  }
  console.log({ stripePromise });
  return stripePromise;
};  // Path: client\src\utils\stripe.js