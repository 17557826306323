/* eslint-disable */
import React, { Component } from "react";

export default class Home extends Component {
  render() {
    return (
      <div className="container home">
        <div className="cards">

          <div className="row">
            <div className="col" style={{marginTop: "20px"}}>
              <a href="register" className="btn btn-success btn-large btn-block text-white" style={{fontSize: "20px"}}>Click here to register</a>
            </div>
          </div>

            <div className="row" style={{marginTop: "20px"}}>
              <div className="col-12">
                <div className="card hsc-card">
                  <div className="card-body">
                    <h5 className="card-title">PLAYER POLICY</h5>
                    <p className="card-text">
                    In order to best protect our players as they participate in our soccer program, we have created a policy that must be adhered to. By registering your child(ren), you are hereby agreeing to the following list of rules: <br />
                    ⁃    All players must wear shin guards.<br />
                    ⁃    All players must wear sneakers or turf shoes. No cleats allowed.<br />
                    ⁃    No spitting on the field.<br />
                    ⁃    All players must maintain a sportsman-like conduct. <br />
                    ⁃    This program does not tolerate any violence whatsoever. When challenging another player for the ball, if a player commits any of the following actions, he/she will be given a verbal warning and the opposing team will receive possession of the ball. If the player, after receiving the first warning, continues to behave poorly and commits any of these actions again, they will be asked to sit out for the remainder of the game. These acts include, but are not limited to, head butting, elbows to the face or head, stomping or kicking a player, shoving, and hair pulling. If a player commits any of these actions outside of a challenge for the ball, and shows unwarranted, excessive violent behavior, he/she will receive a two-game suspension. This policy will be implemented at the discretion of the coaches and with the approval of the program coordinators.<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

          <div className="row">
            <div className="col-md" style={{marginTop: "20px"}}>
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">2020-2021 DATES</h5>
                  <p className="card-text">
                    Week 1: Sept 21<br />
                    Week 2: Sept 28<br />
                    Week 3: Oct 5<br />
                    Week 4: Oct 12<br />
                    Week 5: Oct 19<br />
                    Week 6: Oct 26<br />
                    Week 7: Nov 2<br />
                    Week 8: Nov 9<br />
                    Week 9: Jan 18<br />
                    Week 10: Jan 25<br />
                    Week 11: Feb 1<br />
                    Week 12: Feb 8<br />
                    Week 13: Feb 15<br />
                    Week 14: Feb 22<br />
                    Week 15: Feb 29<br />
                    Week 16: March 7<br />
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md" style={{marginTop: "20px"}}>
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">AGES & TIMES</h5>
                  <h6 className="card-subtitle mb-2 text-muted">Players are divided into the following co-ed age groups and
                    times:</h6>
                  <p className="card-text">
                    <h6>5/6 | 12:30-1:00 </h6>
                    <h6>7/8 | 1:00-2:00 </h6>
                    <h6>9/10 | 2:00-3:00 </h6>
                    <h6>11/12 | 12:30-1:15</h6>
                    <h6>13/14 | 1:20-2:05</h6>
                    <h6>15+ | 2:10-3:00</h6>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md" style={{marginTop: "20px", minWidth: "100px"}}>
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">LOCATION</h5>
                  <h6 className="card-subtitle mb-2 text-muted">We play at an indoor soccer facility called The Soccer Zone.
                  </h6>
                  <p className="card-text">
                    The address of The Soccer Zone is:<br />
                    596 Baldwin St<br />
                    Jenison, MI 49428<br />
                  </p>
                </div>
              </div>
            </div>

          </div>

          <div className="row" style={{marginTop: "20px"}}>

            <div className="col-md-6">
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">COST</h5>
                  <p className="card-text">
                    Players in the 5/6 age group are $35 each.<br />
                    Players in all other age groups are $65 each.<br />
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">WHAT IS INCLUDED? </h5>
                  <p className="card-text">
                    Included in your registration is 16 weeks of play with volunteer coaches and all of the equipment.
                    Pending management approval, we will do a pizza party on the last day.
                  </p>
                </div>
              </div>
            </div>

          </div>

          <div className="row" style={{marginTop: "20px"}}>
            <div className="col-12">
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">PARENT EXPECTATIONS</h5>
                  <p className="card-text">
                  <b>Parents are responsible for:</b><br />
                  - Reading over the player policy with their child(ren) & making sure they adhere to it. <br />
                  - Teaching & encouraging their child(ren) to be respectful of all players, coaches, spectators, & SoccerZone staff. <br />
                  - Closely supervising their child(ren) during their time at SoccerZone (which includes: sitting with or at least near them, whether you are watching your child(ren) play or chatting with a friend or fellow soccer mom; cleaning up any messes made; not letting them run, kick soccer balls around, etc.; and, if they play on the right-side field, picking them up at the appropriate time.) <br />
                  - Remaining in the SoccerZone building the entire time their child(ren) is playing. Your child should not be left unattended in the SoccerZone building, even if they have an older sibling there. We are completely volunteer run – our volunteers are coaches, not babysitters. Your child(ren) are required to have an 18+ family member present in the SoccerZone building at all times. Special exceptions can be made, but must be cleared by the HSP coordinator. For more information, please contact the HSP coordinator.  <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{marginTop: "20px"}}>
            <div className="col-md">
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">WHO ARE THE COACHES?</h5>
                  <p className="card-text">Our coaches are volunteer parents. They are not soccer superstars. Everyone has the
                    ability to teach and encourage willing participants. Our intention is recreational - not overly
                    competitive. The emphasis is on FUN and CHARACTER. </p>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">DO YOU PARTICIPATE IN PARTNERSHIPS?</h5>
                  <p className="card-text">No. This is essentially a giant field trip. One family pays all of the expenses up
                    front and everyone who participates pitches in to cover the expenses. </p>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">WHAT ABOUT SNOW DAYS?</h5>
                  <p className="card-text">We follow Jenison Public Schools for snow days. If they have a snow day; we have a
                    snow day. </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{marginTop: "20px"}}>
            <div className="col">
              <div className="card hsc-card">
                <div className="card-body">
                  <h5 className="card-title">REGISTRATION </h5>
                  <p className="card-text">If you are interested in registering your child(ren), please do so on this website.
                    Consideration is given first to returning families and then new families. </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
              <div className="col" style={{marginTop: "20px"}}>
                <a href="register" className="btn btn-success btn-large btn-block text-white" style={{fontSize: "25px"}}>Click
                  here to register</a>
              </div>
            </div>

        </div>
      </div>
    );
  }
}
