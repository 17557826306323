/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
require('dotenv').config();

export default class Navbar extends Component {
    constructor(props){
      super(props)
      this.state = {
        displayLoading: true,
        port: this.props.port,
        navigation:[],
        userPages: [],
        adminPages: [],
        specialPages: [],
      }
    }   
      
    async componentDidMount(){
      // Check user account type and update if applicable
      let userAccount;
      if(localStorage.getItem("user")) {
        userAccount = JSON.parse(localStorage.getItem("user"))
        await Axios.post(`${this.props.api}/users/`, {id:userAccount.id})
        .then(u => {
          if(u.status === 200){
            if(u.data.type !== userAccount.type) {
              userAccount.type = u.data.type
              localStorage.setItem("user", JSON.stringify(userAccount))
            }
          }
        })
        .catch(e => console.log(e))
      }

      this.loggedIn = localStorage.getItem("user") ? (localStorage.getItem("user").length > 0 ? true : false) : false;
      this.setState({displayLoading: true})
      await Axios.get(`${this.props.api}/nav`)
      .then(res => {
        let defaultNav = res.data.filter(x => x.type === "default" && x.enabled === true);
        this.setState({
          userPages: defaultNav
        }) 

        let adminNav = res.data.filter(x => x.type === "admin" && x.enabled === true);
        let usr = localStorage.getItem("user");
        if(usr && usr.length > 0)
          if(JSON.parse(localStorage.getItem("user")).type === "Admin"){
            this.setState({adminPages: adminNav})
          }
        // console.log()
        console.log(res)

        if(userAccount){
          this.setState({
            specialPages: res.data.filter(p => p.type === userAccount.type && p.enabled === true)
          })
        }

        if(res.status === 200)
          this.setState({displayLoading: false})
      })
      .catch((e) => {
        console.log(e)
      })
      

    }
 
    render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-success">
        <a href="/" className="navbar-brand">
          Homeschool Soccer
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
              {/* Loading */}
              {
                this.state.displayLoading ? <li className="nav-item"><a href='#loading' className="nav-link">Loading navigation...</a></li> : <></>
              }

              {/* Main default nav items */}
              {
                this.state.navigation.map((nav, index) => {
                    return (
                      <li className="nav-item" key={index}>
                        <a href={nav.url} className="nav-link">{nav.title}</a>
                      </li>
                    ) 
                })
              }

              {/* Login/Logout items */}
              {
                this.state.userPages.map((userPage, index) => {
                  if((this.loggedIn && userPage.title === "Login") || (this.loggedIn === false && userPage.title === "Log out") || userPage.title === "Log out"){
                    return (<></>)
                  }
                  return (
                    <li className="nav-item" key={index}>
                      <a href={userPage.url} className="nav-link">{userPage.title}</a>
                    </li>
                  )
                })
              }

              {/* "Admin" pages */}
              {
                this.state.adminPages.map((adminPage, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <a href={adminPage.url} className="nav-link">{adminPage.title}</a>
                    </li>
                  )
                })
              }

              {/* "Special pages" - display based off account type */}
              {
                this.state.specialPages && this.state.specialPages.map((page, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <a href={page.url} className="nav-link">{page.title}</a>
                    </li>
                  )
                })
              }

              {/* "Display logout page" */}
              {
                this.state.userPages.map((userPage, index) => {
                  if(userPage.title === "Log out" && this.loggedIn)
                    return (
                    <li className="nav-item" key={index}>
                      <a href={userPage.url} className="nav-link">{userPage.title}</a>
                    </li>
                    )
                  else return(<></>)
                })
              }
          </ul>
        </div>
      </nav>
    );
  }
}
