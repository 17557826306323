/* eslint-disable */
import React, { Component } from "react";
import RegisterControl from "./admin.register-control.component";
import UserControl from "./admin.user-control.component";

export default class Admin extends Component {
  constructor(props){
    super(props)
    this.state = {
      port: this.props.port
    }
    this.user = this.props.user || JSON.parse(localStorage.getItem("user"))
  }

  componentWillMount(){
    if((this.user && this.user.type && this.user.type !== "Admin" && this.user.type !== "Super Admin") || !this.user.type) {
      // console.log("not admin", this.props.user.type)
      window.location.href = '/'
    }
  }

  render() {
    return (
      <div>
          <RegisterControl port={this.props.port} user={this.user} api={this.props.api}/>
          <hr />
          <UserControl port={this.props.port} user={this.user} api={this.props.api}/>
      </div>
    );
  }
}
